/* global BigInt */

import logo from "./assets/app-logo.svg";
import { useState, useEffect, useRef } from "react";
import { ReactComponent as SendIcon } from "./assets/send.svg";
import { ReactComponent as ClaimIcon } from "./assets/claim.svg";
import { ReactComponent as ExpandIcon } from "./assets/expand_more.svg";
import { ReactComponent as TwitterIcon } from "./assets/twitter-icon.svg";
import { ReactComponent as LinkIcon } from "./assets/external-link-line.svg";
import solanaIcon from "./assets/Solana_logo.png";
import moment from "moment";
import base58 from "bs58";
import {
  Tabs,
  Modal,
  Select,
  Button,
  Input,
  InputNumber,
  notification,
  Popover,
} from "antd";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import selected from "./assets/selected.svg";
import notselected from "./assets/not-select.svg";
import { PhantomWalletName } from "@solana/wallet-adapter-phantom";
import {
  PublicKey,
  SystemProgram,
  TransactionMessage,
  VersionedTransaction,
  TransactionInstruction,
  SolanaJSONRPCError,
  Ed25519Program,
  Connection,
} from "@solana/web3.js";
import {
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  getAssociatedTokenAddressSync,
  TokenAccountNotFoundError,
  TokenInvalidAccountOwnerError,
  createAssociatedTokenAccountInstruction,
} from "@solana/spl-token";
import { enqueueSnackbar } from "notistack";
import { serialize } from "borsh";
import { useWallet } from "@solana/wallet-adapter-react";

import {
  getAccountInfoApi,
  getAssetData,
  getClaimData,
  getSupportAsset,
  getWalletHistory,
  getWalletHistoryByAddress,
} from "./api";

import "./tipapp.css";

const TOKEN_KEY = "twitter-token";

function SendItem({ item, remove, update }) {
  const [address, setAddress] = useState("");
  const [count, setCount] = useState("");
  const [addressStatus, setAddressStatus] = useState("");
  const [countStatus, setCountStatus] = useState("");

  const validateTwitterOrAddress = async () => {
    setCountStatus(!count ? "error" : "");
    let status = true;
    // if (item.type === "wallet") {
    //   status = isAddress(address);
    // }
    // if (item.type === "twitter") {
    //   try {
    //     const res = await validateTwitterUserApi({ username: address });
    //     status = !!res.data.username;
    //   } catch (error) {
    //     status = false;
    //   }
    // }
    setAddressStatus(!status ? "error" : "");
  };

  useEffect(() => {
    update({ ...item, address, count, addressStatus, countStatus });
  }, [address, count, addressStatus, countStatus]);

  return (
    <div className="send-item">
      <Input
        className="address"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        status={addressStatus}
        onBlur={validateTwitterOrAddress}
        prefix={
          item.type === "wallet" ? (
            <TwitterIcon />
          ) : (
            <>
              <TwitterIcon />@
            </>
          )
        }
      />
      <InputNumber
        className="count"
        value={count}
        controls={false}
        status={countStatus}
        formatter={(value) => Number(value)?.toString()}
        onChange={(e) => setCount(e || undefined)}
        onBlur={() => setCountStatus(!count ? "error" : "")}
      />
      {/* <CloseIcon className="remove" onClick={remove} /> */}
    </div>
  );
}

const TipApp = () => {
  const twitterConnected = localStorage.getItem(TOKEN_KEY) !== null;

  const intervalRef = useRef(0);
  const [AppModalOpen, setAppModalOpen] = useState(true);
  const [claimModalOpen, setClaimModalOpen] = useState(false);
  const [sendTargets, setSendTargets] = useState([
    { key: moment().unix(), type: "twitter" },
  ]);
  const [claimAssetId, setClaimAssetId] = useState(0);
  const [balance, setBalance] = useState([]);
  const [history, setHistory] = useState([]);
  const { publicKey, sendTransaction, connect, wallet, select } = useWallet();
  const [DepositModalOpen, setDepoSitModalOpen] = useState(false);
  const [supportAssets, setSupportAssets] = useState([]);
  const [depositAsset, setDepositAsset] = useState();
  const [userInfo, setUserInfo] = useState("");
  // const { connection } = useConnection();
  const connection = new Connection(
    "https://broken-omniscient-silence.solana-mainnet.quiknode.pro/f235caaf725e94dea27eb7f9e6a6a838c2820ad6/"
  );

  const getTwitterInfo = async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    try {
      const res = await getAccountInfoApi({ token });
      console.log(res);
      setUserInfo(res.data.extra.twitter_username);
    } catch (error) {
      console.log(error);
    }
  };

  const getTransferHistory = () => {};

  useEffect(() => {
    getTwitterInfo();
  }, []);

  useEffect(() => {}, [twitterConnected]);

  async function getBalance() {
    try {
      const res = await getAssetData({ network: "solana_mainnet" });
      const list = res.data.data;
      setBalance(list.filter((b) => !!Number(b.balance)));
      // if (claimAssetId === -1)
      //   setClaimAssetId(list.filter((b) => !!Number(b.balance))[0]?.asset_id);
    } catch (error) {
      console.log(error);
    }
  }
  async function getSupportDepositAssets() {
    try {
      const res = await getSupportAsset({ network: "solana_mainnet" });
      const list = res.data.data[0].asset_list;
      console.log(list, "support");
      setSupportAssets(list);
      setDepositAsset(list[0]);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getBalance();
    getSupportDepositAssets();
  }, []);

  useEffect(() => {
    clearInterval(intervalRef.current);
    if (!publicKey && !twitterConnected) return;

    const id = setInterval(async () => {
      const params = {};
      if (publicKey) params.address = publicKey.toBase58();
      try {
        const res = await getWalletHistoryByAddress(params);
        setHistory(res.data.data);
        getBalance();
      } catch (error) {
        console.log(error);
      }
    }, 5000);
    intervalRef.current = id;
  }, [publicKey, twitterConnected]);

  useEffect(() => {
    if (claimModalOpen) {
      if (!wallet) {
        select(PhantomWalletName);
        return;
      }
      // if (!localStorage.getItem(TOKEN_KEY)) {
      //   connectTwitter();
      //   return;
      // }
      if (!twitterConnected) {
        setTimeout(() => {
          connectTwitter();
        }, 1000);
      }
    }
  }, [claimModalOpen, select, wallet, twitterConnected]);
  useEffect(() => {
    if (DepositModalOpen) {
      if (!wallet) {
        select(PhantomWalletName);
        return;
      }
      // if (!localStorage.getItem(TOKEN_KEY)) {
      //   connectTwitter();
      //   return;
      // }
      // if (!twitterConnected) {
      //   setTimeout(() => {
      //     connectTwitter();
      //   }, 1000);
      // }
    }
  }, [DepositModalOpen, select, wallet]);

  const decodeToken = async () => {
    const { search, origin, pathname } = window.location;
    const params = new URLSearchParams(search);
    const urlToken = params.get("token");
    const errNo = params.get("errno");
    const errMsg = params.get("errmsg");
    // twitter
    if (errNo && errNo !== "0") {
      window.history.pushState("", "", `${origin}${pathname}`);
      // notify({
      //   type: "warning",
      //   message: "",
      //   description: errMsg,
      //   longMessage: (errMsg?.length || 0) > 200,
      // });
    }
    if (urlToken) {
      window.history.pushState("", "", `${origin}${pathname}`);
      localStorage.setItem(TOKEN_KEY, urlToken);
    }
    const token = urlToken || localStorage.getItem(TOKEN_KEY);
    getTwitterInfo();
    if (!token) {
      return;
    }
  };

  useEffect(() => {
    decodeToken();
  }, []);

  const programId = new PublicKey(
    "BXBe4smFkyVgNoHMEaZYFMjgTKRpDzZgv8e7kWq8YUJS"
  );

  const getVault = async (mintKey) => {
    let tokenKey = new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA");
    let seed = [Buffer.from("vault"), tokenKey.toBuffer(), mintKey.toBuffer()];

    let [pdaAddress, bump] = await PublicKey.findProgramAddressSync(
      seed,
      programId
    );
    return pdaAddress;
  };
  const getVaultSpl = async (mintKey) => {
    let tokenKey = new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA");
    let seed = [
      Buffer.from("vault_spl"),
      tokenKey.toBuffer(),
      mintKey.toBuffer(),
    ];
    let [pdaAddress, bump] = await PublicKey.findProgramAddressSync(
      seed,
      programId
    );
    return pdaAddress;
  };

  const formatHash = (hash) => {
    return `${hash.substring(0, 4)}...${hash.substring(hash.length - 4)}`;
  };

  const balanceOperateList = [
    {
      name: "tip",
      click: () => {
        setAppModalOpen(false);
        setDepoSitModalOpen(true);
      },
      icon: <SendIcon />,
    },
    {
      name: "claim",
      click: () => {
        setAppModalOpen(false);
        setClaimModalOpen(true);
      },
      icon: <ClaimIcon />,
    },
  ];

  const tabList = [
    {
      label: "Assets",
      key: "assets",
      children: (
        <div className="assets">
          {balance.map((c) => (
            <div className="coin" key={c.asset_id}>
              <div className="coin-name">
                <img
                  className="coin-icon"
                  src={c.asset_icon || solanaIcon}
                  alt=""
                />
                {c.asset_symbol.toUpperCase()}
              </div>
              <div className="coin-value">
                {Number(c.balance).toFixed(2)}
                {c.asset_price > 0 && (
                  <span>
                    ($
                    {(Number(c.balance) * c.asset_price).toFixed(2)})
                  </span>
                )}
              </div>
            </div>
          ))}
          {balance.length === 0 && <div className="no-asset">No Asset</div>}
        </div>
      ),
    },
    {
      label: "History",
      key: "history",
      children: (
        <div className="history assets">
          {history.map((c, i) => (
            <div
              className={`history-item ${c.record_type}`}
              key={i + c.state + c.timestamp}
            >
              <div className="date">
                {moment.unix(c.timestamp).format("YYYY/MM/DD HH:mm:ss")}
              </div>
              <div className="type">
                <div className="type-label">
                  {c.record_type}
                  <span className={`state ${c.state}`}>{c.state}</span>
                </div>
                <div className="hash">
                  {c.extra_info.reciver_name.length > 0 && (
                    <>{c.extra_info.reciver_name}</>
                  )}
                  {c.extra_info.reciver_name.length === 0 && c.tx_hash && (
                    <>
                      {formatHash(c.tx_hash)}
                      <LinkIcon
                        className="open-tx-link"
                        onClick={() => {
                          window.open(`https://solscan.io/tx/${c.tx_hash}`);
                        }}
                      ></LinkIcon>
                    </>
                  )}
                </div>
              </div>
              <div className="record">
                <div className="record-coin">
                  <div className="coin-name">
                    <img
                      className="coin-icon"
                      src={c.asset_info.icon || solanaIcon}
                      alt=""
                    />
                    {c.coin_symbol.toUpperCase()}
                  </div>
                  <div className="value">
                    {c.record_type === "Deposit" ||
                    c.record_type === "Claim Return"
                      ? "+"
                      : ""}
                    {Number(c.value).toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
          ))}
          {history.length === 0 && <div className="no-asset">No History</div>}
        </div>
      ),
    },
  ];

  const onTransfer = async (txInstructions) => {
    enqueueSnackbar(`SystemProgram: ${SystemProgram.programId.toBase58()}`);

    const {
      context: { slot: minContextSlot },
      value: { blockhash, lastValidBlockHeight },
    } = await connection.getLatestBlockhashAndContext();
    //let latestBlockhash = await connection.getLatestBlockhash("finalized");
    enqueueSnackbar(
      `   ✅ - Fetched latest blockhash. Last Valid Height: 
      ${lastValidBlockHeight}`
    );

    const messageV0 = new TransactionMessage({
      payerKey: publicKey,
      recentBlockhash: blockhash,
      instructions: txInstructions,
    }).compileToV0Message();

    const trx = new VersionedTransaction(messageV0);
    const signature = await sendTransaction(trx, connection, {
      minContextSlot,
      // signers: [publicKey],
    });
    console.log("signature:", signature);
    notification.info({
      message: `click to view the transcation on solscan`,
      placement: "bottomRight",
      onClick: () => {
        window.open(`https://solscan.io/tx/${signature}`);
      },
    });
  };
  const getKeyAndTi = async () => {
    // if (!wallet) {
    //   select(PhantomWalletName);
    //   return;
    // }
    // if (!localStorage.getItem(TOKEN_KEY)) {
    //   connectTwitter();
    //   return;
    // }
    // console.log(SystemProgram.programId.toBase58());
    const mintKey = new PublicKey(depositAsset.contract_addr);
    const srcKey = getAssociatedTokenAddressSync(
      new PublicKey(depositAsset.contract_addr),
      publicKey,
      true,
      TOKEN_PROGRAM_ID,
      ASSOCIATED_TOKEN_PROGRAM_ID
    );

    const vaultKey = await getVault(mintKey);
    const vaultSplKey = await getVaultSpl(mintKey);
    const meKey = publicKey;

    const splTokenKey = new PublicKey(
      "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"
    );
    const sysKey = new PublicKey("11111111111111111111111111111111");
    const rentKey = new PublicKey(
      "SysvarRent111111111111111111111111111111111"
    );
    function Ix({ to, id, amount }) {
      this.to = to;
      this.id = id;
      this.amount = amount;
    }

    const transferIx = new Ix({
      id: 0,
      to: sendTargets[0].address,
      amount: BigInt(sendTargets[0].count * 10 ** depositAsset.decimal),
    });
    const transferSerBuf = Buffer.from(
      serialize(
        { struct: { id: "u8", to: "string", amount: "u64" } },
        transferIx
      )
    );
    const ti = [
      new TransactionInstruction({
        data: transferSerBuf,
        keys: [
          { pubkey: srcKey, isSigner: false, isWritable: true },
          { pubkey: vaultKey, isSigner: false, isWritable: true },
          { pubkey: vaultSplKey, isSigner: false, isWritable: true },
          { pubkey: meKey, isSigner: false, isWritable: true },
          { pubkey: mintKey, isSigner: false, isWritable: false },
          { pubkey: splTokenKey, isSigner: false, isWritable: false },
          { pubkey: sysKey, isSigner: false, isWritable: false },
          { pubkey: rentKey, isSigner: false, isWritable: false },
        ],
        programId: new PublicKey(
          "BXBe4smFkyVgNoHMEaZYFMjgTKRpDzZgv8e7kWq8YUJS"
        ),
      }),
    ];
    onTransfer(ti);
  };

  const onClaim = async () => {
    if (!wallet) {
      select(PhantomWalletName);
      return;
    }
    let claimData;
    try {
      const asset = balance.find((asset) => asset.asset_id === claimAssetId);
      const data = await getClaimData({
        network: "solana_mainnet",
        asset_id: asset.asset_id,
        amount: Number(asset.balance),
        timestamp: Date.now(),
        recv_addr: publicKey,
      });
      claimData = data.data.data;
    } catch (error) {
      console.log(error);
    }
    const ix = {
      id: 1,
      amount: BigInt(Number(claimData.amount)),
      nonce: BigInt(claimData.nonce),
      slot: BigInt(claimData.under_block),
      signature: claimData.sign,
    };
    const transferSerBuf = Buffer.from(
      serialize(
        {
          struct: {
            id: "u8",
            amount: "u64",
            nonce: "u64",
            slot: "u64",
            signature: "string",
          },
        },
        ix
      )
    );

    const WithdrawKey = getAssociatedTokenAddressSync(
      new PublicKey(claimData.token_addr),
      publicKey,
      true,
      TOKEN_PROGRAM_ID,
      ASSOCIATED_TOKEN_PROGRAM_ID
    );

    const mintKey = new PublicKey(claimData.token_addr);
    let ti = [];
    if (claimData.token_addr !== "1".repeat(32))
      try {
        await connection.getTokenAccountBalance(WithdrawKey);
      } catch (e) {
        if (
          e instanceof TokenAccountNotFoundError ||
          e instanceof TokenInvalidAccountOwnerError ||
          (e instanceof SolanaJSONRPCError && e.code == -32602)
        ) {
          let cataI = createAssociatedTokenAccountInstruction(
            publicKey,
            WithdrawKey,
            publicKey,
            mintKey,
            TOKEN_PROGRAM_ID,
            ASSOCIATED_TOKEN_PROGRAM_ID
          );
          ti.push(cataI);
        } else {
          console.log("e is typeof:%O", typeof e);
        }
      }

    const vaultKey = await getVault(mintKey);
    const vaultSplKey = await getVaultSpl(mintKey);
    const meKey = publicKey;

    const splTokenKey = new PublicKey(
      "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"
    );
    const sysKey = new PublicKey("11111111111111111111111111111111");
    const rentKey = new PublicKey(
      "SysvarRent111111111111111111111111111111111"
    );
    const instructionKey = new PublicKey(
      "Sysvar1nstructions1111111111111111111111111"
    );
    const adminKey = new PublicKey(
      "GkqtGnXZDkmSM3t7Y7ApkzBqpHLPC9kMrzA7yYY6p6xL"
    );
    let firstTi = Ed25519Program.createInstructionWithPublicKey({
      publicKey: adminKey.toBytes(),
      message: Buffer.from(claimData.extra_data.msg),
      signature: base58.decode(claimData.sign),
    });
    const nonce_bank_key = new PublicKey(claimData.extra_data.nonce_bank);
    ti.push(
      firstTi,
      new TransactionInstruction({
        data: transferSerBuf,
        keys: [
          { pubkey: meKey, isSigner: false, isWritable: true },
          { pubkey: vaultKey, isSigner: false, isWritable: true },
          { pubkey: vaultSplKey, isSigner: false, isWritable: true },
          { pubkey: WithdrawKey, isSigner: false, isWritable: true },
          { pubkey: nonce_bank_key, isSigner: false, isWritable: true },
          { pubkey: mintKey, isSigner: false, isWritable: false },
          { pubkey: splTokenKey, isSigner: false, isWritable: false },
          { pubkey: sysKey, isSigner: false, isWritable: false },
          { pubkey: instructionKey, isSigner: false, isWritable: false },
        ],
        programId: new PublicKey(claimData.contract_addr),
      })
    );
    console.log(ti);

    setClaimModalOpen(false);
    setAppModalOpen(true);

    onTransfer(ti);
  };

  const connectTwitter = () => {
    const timestamp = Date.now();
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const third_channel = params.get("third_channel") || "";
    const url = `https://mainnet-lb.famchat.io/sns/twitter/request_authorize?operate=login&source=tipto&redirect=${window.location.href}&t=${timestamp}`;
    console.log(third_channel);

    window.location.href = url;
  };

  const disconnect = () => {
    localStorage.removeItem(TOKEN_KEY);
    setUserInfo("");
    window.location.reload();
  };

  const content = (
    <>
      {twitterConnected && (
        <div>
          <Button onClick={disconnect}>Disconnect</Button>
        </div>
      )}
      {!twitterConnected && (
        <div>
          <Button onClick={connectTwitter}>connect </Button>
        </div>
      )}
    </>
  );
  return (
    <div className="app-container">
      <div className="connect-button">
        <Popover content={content} trigger={"click"}>
          <Button className="twitter-connect">
            <TwitterIcon />
            {userInfo}
          </Button>
        </Popover>
        <WalletMultiButton />
      </div>
      <img className="logo" src={logo}></img>

      <Modal
        open={AppModalOpen}
        // onCancel={() => setAppModalOpen(false)}
        closeIcon={false}
        footer={null}
        width={500}
      >
        <div className="modal-title">
          <div>My Balance</div>
        </div>
        {/* <div className="balance-detail">$5250</div> */}
        <div className="balance-modal">
          <div className="total">
            {/* ${totalWealth.toFixed(2).toLocaleString()}
            <div className="label">
              <BalanceIcon />
              <span className="label">MY BALANCE</span>
            </div> */}
          </div>
          <div className="buttons">
            {balanceOperateList.map((op) => (
              <div className="btn" key={op.name} onClick={op.click}>
                <div className="icon">{op.icon}</div>
                <div className="name">{op.name}</div>
              </div>
            ))}
          </div>
          <Tabs defaultActiveKey="assets" centered items={tabList} />
          {/* <div
            className="contact"
            onClick={() => window.open(`https://t.me/JaylenChan`)}
          >
            Meet problems? Please contact us
          </div> */}
        </div>
      </Modal>

      <Modal
        open={claimModalOpen}
        onCancel={() => {
          setClaimModalOpen(false);
          setAppModalOpen(true);
        }}
        // closeIcon={<CloseIcon />}
        footer={null}
        width={440}
      >
        <div className="modal-title">
          <div>Claim</div>
        </div>
        <>
          {twitterConnected && (
            <div className="claim-modal">
              <div className="tips-title">
                {balance.length > 0
                  ? "Select asset to claim"
                  : "No assets to claim"}
              </div>
              {balance.map((c) => {
                const isSelected = c.asset_id === claimAssetId;
                return (
                  <div
                    className={`coin-select-item large claim ${
                      isSelected ? "selected" : ""
                    }`}
                    onClick={() => {
                      setClaimAssetId(c.asset_id);
                    }}
                  >
                    <img
                      className="coin-icon"
                      src={c.asset_icon || solanaIcon}
                      alt=""
                    />
                    <div className="coin-name">
                      <span className="symbol">
                        {c.asset_symbol.toUpperCase()}
                      </span>
                      <span className="name">({c.asset_name})</span>
                    </div>
                    <div className="amount">{Number(c.balance).toFixed(2)}</div>
                    <img src={isSelected ? selected : notselected} />
                  </div>
                );
              })}
              <div className="modal-buttons">
                <Button
                  className="deposit-btn back"
                  onClick={() => {
                    setClaimModalOpen(false);
                    setAppModalOpen(true);
                  }}
                >
                  Back
                </Button>
                <Button className="deposit-btn copy" onClick={onClaim}>
                  Next
                </Button>
              </div>
            </div>
          )}
          {!twitterConnected && (
            <div className="twitter-connecting">connecting twitter...</div>
          )}
        </>
      </Modal>
      <Modal
        open={DepositModalOpen}
        onCancel={() => {
          setAppModalOpen(true);
          setDepoSitModalOpen(false);
        }}
        // closeIcon={<CloseIcon />}
        footer={null}
        width={440}
      >
        <div className="modal-title">
          <div>Tip</div>
        </div>
        <div className="send-modal">
          <div className="item">
            <div className="label">Asset</div>
            <div className="value">
              <Select
                style={{ width: 346, height: 53 }}
                value={depositAsset?.asset_id}
                onChange={(value) =>
                  setDepositAsset(
                    supportAssets.find((c) => c.asset_id === value)
                  )
                }
                suffixIcon={<ExpandIcon />}
                options={supportAssets.map((c) => ({
                  value: c.asset_id,
                  label: (
                    <div className="coin-select-item">
                      <img
                        className="coin-icon"
                        src={c.asset_icon || solanaIcon}
                        alt=""
                      />
                      <span className="coin-name">
                        {c.asset_symbol.toUpperCase()}
                      </span>
                      {/* <span className="amount">
                          ({Number(c.balance).toFixed(2)})
                        </span> */}
                    </div>
                  ),
                }))}
              />
            </div>
          </div>
          <div className="item">
            <div className="label">To</div>
            <div className="value">
              {sendTargets.map((target) => (
                <SendItem
                  key={target.key}
                  item={target}
                  update={(newTarget) =>
                    setSendTargets(
                      sendTargets.map((t) => {
                        if (t.key === target.key) {
                          return newTarget;
                        }
                        return t;
                      })
                    )
                  }
                  remove={() =>
                    setSendTargets(
                      sendTargets.filter((t) => t.key !== target.key)
                    )
                  }
                />
              ))}
            </div>
          </div>
          <div className="modal-buttons">
            <Button
              className="deposit-btn back"
              onClick={() => {
                setAppModalOpen(true);
                setDepoSitModalOpen(false);
              }}
            >
              Back
            </Button>
            <Button
              className="deposit-btn copy"
              // disabled={sendDisabled}
              onClick={getKeyAndTi}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TipApp;
