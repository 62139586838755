import Pig from "./assets/pig.png";
import ClaimImg from "./assets/ClaimImg.svg";
import logo from "./assets/logo.svg";
import "./start.css";
const ClaimPage = () => {
  return (
    <div className="start-container">
      <div className="left">
        <img className="logo" src={logo}></img>

        <img
          className="start-img bg-img"
          style={{ width: "330px" }}
          src={ClaimImg}
        ></img>
        <img className="start-img" src={Pig}></img>
      </div>
      <div className="right">
        <div className="right-container">
          <div className="start-text">Claim Your Reward</div>
          <div className="start-text-title">
            You can claim rewards given to you by others at any time.
          </div>
          <div
            className="go-app-item"
            onClick={() => {
              window.location.href = "/app";
            }}
          >
            GO TO APP
          </div>
        </div>
      </div>
    </div>
  );
};
export default ClaimPage;
