import axios from "axios";

const tokenKey = "twitter-token";

axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem(tokenKey);

    const headers = config.headers || {};
    headers.token = token || "";
    return {
      ...config,
      headers,
    };
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const getAssetData = (params) => {
  return axios.get("https://mainnet-lb.famchat.io/chaindata/wallet/balance", {
    params,
  });
};
export const getClaimData = (params) => {
  return axios.post(
    "https://mainnet-lb.famchat.io/chaindata/wallet/claims_asset",
    {
      ...params,
    }
  );
};

export const getWalletHistory = (params) => {
  return axios.get(
    "https://mainnet-lb.famchat.io/chaindata/wallet/change_history",
    {
      params,
    }
  );
};
export const getSupportAsset = (params) => {
  return axios.get(
    "https://mainnet-lb.famchat.io/chaindata/wallet/support_assets",
    {
      params,
    }
  );
};

export const sendAssetApi = async (data) => {
  return axios.post(
    `https://mainnet-lb.famchat.io/chaindata/wallet/send_asset`,
    {
      ...data,
    }
  );
};

export const getAccountInfoApi = async (data) => {
  return axios.post(`https://mainnet-lb.famchat.io/account/info`, {
    ...data,
  });
};

export const getWalletHistoryByAddress = async (params) => {
  return axios.get(
    `https://mainnet-lb.famchat.io/chaindata/tipto/wallet_history`,
    {
      params,
    }
  );
};
