import twitterBg from "./assets/TwitterBgimg.png";
import twitter from "./assets/Twitter.svg";
import logo from "./assets/logo.svg";
import rightArrow from "./assets/arrow-right-circle-line.svg";

import "./start.css";
const TipOnTwitter = () => {
  return (
    <div className="start-container">
      <div className="left">
        <img className="logo" src={logo}></img>

        <img className="start-img bg-img" src={twitter}></img>
        <img className="start-img" src={twitterBg}></img>
      </div>
      <div className="right">
        <div className="right-container">
          <div className="start-text">Tip Anyone on X</div>
          <div className="start-text-title">
            Tip anyone on X just using their twitter handle, multiple assets on
            Solana are supported.
          </div>
          <div className="start-text-title">
            You can also tip anyone by commenting under their threads, your
            support is recognised!
          </div>
          <div className="start-text-title">
            You do not need any set up to received tips, the assets tipped to
            you can be claimed anytime once you connected your account.
          </div>
          <div className="bottom-item-container">
            <div
              className="go-app-item"
              onClick={() => {
                window.location.href = "/claim";
              }}
            >
              <img src={rightArrow}></img>
            </div>
            <div
              className="go-app-item"
              onClick={() => {
                window.location.href = "/app";
              }}
            >
              GO TO APP
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TipOnTwitter;
