import startImg from "./assets/start.png";
import logo from "./assets/logo.svg";
import rightArrow from "./assets/arrow-right-circle-line.svg";
import "./start.css";
const Start = () => {
  return (
    <div className="start-container">
      <div className="left">
        <img className="logo" src={logo}></img>
        <img className="start-img" src={startImg}></img>
      </div>
      <div className="right">
        <div className="right-container">
          <div className="start-text">Get Started</div>
          <div className="start-text-title">
            Use X account to create your own Web3 wallet, and deposit crypto
            assets, simply and easily.
          </div>
          <div className="bottom-item-container">
            <div
              className="go-app-item"
              onClick={() => {
                window.location.href = "/tip";
              }}
            >
              <img src={rightArrow}></img>
            </div>
            <div
              className="go-app-item"
              onClick={() => {
                window.location.href = "/app";
              }}
            >
              GO TO APP
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Start;
