import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import "@solana/wallet-adapter-react-ui/styles.css";

import "./index.css";
import App from "./App";
import Start from "./Start";
import TipOnTwitter from "./TipOnTwitter";
import ClaimPage from "./ClaimPage";
import reportWebVitals from "./reportWebVitals";
import TipApp from "./TipApp";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { clusterApiUrl } from "@solana/web3.js";
import { SnackbarProvider } from "notistack";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-phantom";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { SolongWalletAdapter } from "@solana/wallet-adapter-solong";

import { Buffer } from "buffer";

window.Buffer = Buffer;

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/start",
    element: <Start />,
  },
  {
    path: "/Tip",
    element: <TipOnTwitter />,
  },
  {
    path: "/claim",
    element: <ClaimPage />,
  },
  {
    path: "/app",
    element: <TipApp />,
  },
]);

const network = WalletAdapterNetwork.Mainnet;
const endpoint = clusterApiUrl(network);
const wallets = [new SolongWalletAdapter(), new PhantomWalletAdapter()];

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <SnackbarProvider maxSnack={10}>
            {/* <CssBaseline /> */}
            {/* <Header customButton={customHeaderButton} /> */}
            <RouterProvider router={router} />
            {/* <Footer /> */}
          </SnackbarProvider>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
